












import Vue from "vue";
import { mapState } from "vuex";
import { Dialog, Toast } from "vant";
import { dispatch } from "@/store";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("profile", {
      nickname: "nickname",
      uuid: "uuid",
    }),
    redirect(): string {
      if (this.$route.query.redirect) {
        return this.$route.query.redirect + "";
      }
      return this.$paths.home;
    },
  },
  methods: {
    onSignIn() {
      window.location.href = this.redirect;
    },
    onSignOut() {
      dispatch.appSignOut().then(() => {
        window.location.href = this.$paths.authSignIn;
      });
    },
    onDeregister() {
      Dialog.confirm({
        title: "注销账户",
        message:
          "注销后将无法访问此账户下在 H5 商城及怒喵官网下的所有数据，使用相同方式再次登录后将获得新的账户，确认要注销此账户吗？",
      }).then(() => {
        dispatch.appDeregister().then(() => {
          Toast("账户已完成注销");
          this.onSignOut();
        });
      });
    },
  },
  created() {
    if (!this.uuid) {
      this.$router.replace(this.$paths.authSignIn);
    }
  },
});
